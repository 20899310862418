<template>
  <component :is="layout"></component>
</template>

<script>

import LayoutLogin from './views/LayoutLogin.vue'
import LayoutMain from './views/LayoutMain.vue'
import styles from './assets/app.css'

export default {
  name: 'App',

  computed: {
    layout() {
      return 'Layout' + (this.$route.meta.layout || 'Login')
    }
  },
  components: {
    LayoutLogin, LayoutMain,
  },
}
</script>






