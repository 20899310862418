<template>
  <v-app id="lk-kapremont19" class="gp-full-height">
    <v-navigation-drawer v-model="drawer" app style="height: 100%">
      <v-list-item>
        <v-list-item-content>
          <v-img src="img/app-logo-text.svg"></v-img>
          <v-list-item-title class="caption mt-4 text-center font-weight-medium">
            {{ customerTitle }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list nav>
        <v-list-item
            v-for="item in items"
            :key="item.to"
            :to="item.to"
            link
            color="var(--color-brand-blue)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <div class="caption mb-0 text-center" style="color:var(--color-brand-gray);">Авторизация: {{ $store.state.authDate }}</div>
          <div class="caption mb-3 text-center" style="color:var(--color-brand-gray);">Актуальность: {{ $store.state.actualDate }}</div>
          <v-btn block outlined class="mb-4" color="var(--color-red)" @click.stop="dialogUpdate = true" v-if="snackWithButtons">
            <v-icon left>mdi-cellphone-arrow-down</v-icon>Обновить</v-btn>
          <v-btn block @click="logout">Выход</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="var(--color-brand-gray-light)">
      <!--<v-icon left>mdi-arrow-left</v-icon>-->
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ appTitleText }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-dialog v-model="dialogUpdate" persistent max-width="400" v-if="snackWithButtons">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="var(--color-red)" v-bind="attrs" v-on="on" outlined>
            <v-icon left>mdi-cellphone-arrow-down</v-icon>
            Обновить
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">Доступна новая версия</v-card-title>
          <v-card-text class="mt-4">
            <p class="text-h6">Для обновления приложения выполните следующий действия:</p>
            <p class="text-body-1">1) На мобильном телефоне: Потяните вниз экран приложения.</p>
            <p class="text-body-1">2) На компьютере: В браузере, нажмите сочетание клавиш SHIFT+F5.</p>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogUpdate = false">Понятно</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-app-bar>

    <v-main>
      <router-view></router-view>
      <div class="d-flex d-md-none" style="height: 56px;"></div>
    </v-main>

    <v-bottom-navigation :value="value" fixed class="d-flex d-md-none">

      <v-btn v-for="btn in btns" :key="btn.to" :to="btn.to">
        <span>{{ btn.title }}</span>
        <v-icon>{{ btn.icon }}</v-icon>
      </v-btn>

    </v-bottom-navigation>
  </v-app>
</template>

<script>

import debounce from 'lodash/debounce'

export default {
  props: {
    source: String,
  },
//  name: 'FullHeight',
  name: 'LayoutMain',
  data() {
    return {
      value: 1,
      drawer: null,
      item: {},
      items: [],
      btn: {},
      btns: [],
      dialog: false,
      summ: 0,
      loadingData: false,
      dialogUpdate: false,
      refreshing: false,
      registration: null,
      snackBtnText: '',
      //snackWithBtnText: '',
      snackWithButtons: false,
    }
  },
  created() {
    //console.log('created')
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('serviceWorkerUpdateEvent', this.showRefreshUI, { once: true })
    //document.addEventListener('serviceWorkerUpdateEvent', this.showRefreshUI, {})
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        console.log('Application update.')
        window.location.reload()
      })
    }
  },
  mounted() {
    //console.log(this.$store.getters.conf)
    this.fillMenuItems()
    this.fillBottomItems()
    this.wsCreateConnection()

    if (this.$store.state.mode == 'full') this.$store.dispatch('loadDashboard')

    this.setViewHeight()
    const debouncedSetHeight = debounce(this.setViewHeight, 50)
    window.addEventListener('resize', debouncedSetHeight)
    this.$once('destroyed', () => {
      window.removeEventListener('resize', debouncedSetHeight)
    })
  },
  methods: {
    showRefreshUI(e) {
      // Display a button inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      //console.log('showRefreshUI')
      //console.log(e)
      //console.log('showRefreshUI')
      //this.snackWithBtnText = 'New version available!'
      this.registration = e.detail
      this.snackWithButtons = true
    },
    refreshApp() {
      //console.log('refreshApp')
      this.snackWithButtons = false
      this.dialogUpdate = false
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return }
      this.registration.waiting.postMessage('skipWaiting')
    },
    fillMenuItems() {
      switch (this.$store.state.mode) {
        case 'full':
          this.items = [
            {to: "/dashboard", title: "Обзор", icon: "mdi-monitor-dashboard"},
            {to: "/accounts", title: "Помещения", icon: "mdi-account-box-multiple"},
            {to: "/calculations", title: "Начисления", icon: "mdi-calculator-variant"},
            {to: "/payment", title: "Оплата", icon: "mdi-credit-card"},
            {to: "/reg-program", title: "Ремонт МКД", icon: "mdi-office-building"},
//            {to: "/inspection", title: "Народный контроль", icon: "mdi-comment-account"},
            {to: "/messaging", title: "Обращения", icon: "mdi-comment"},
            {to: "/my-data", title: "Мои данные", icon: "mdi-account-details"},
//            {to: "/components", title: "Компоненты(DEV)", icon: "mdi-square"},
          ]
        break
        case 'short':
          this.items = [
            {to: "/dashboard-simple", title: "Обзор", icon: "mdi-monitor-dashboard"},
            {to: "/calculations-simple", title: "Начисления", icon: "mdi-calculator-variant"},
            {to: "/reg-program-simple", title: "Ремонт МКД", icon: "mdi-office-building"},
//            {to: "/components", title: "Компоненты(DEV)", icon: "mdi-square"},
          ]
        break
//        default: this.logout()
      }
    },
    fillBottomItems() {
      switch (this.$store.state.mode) {
        case 'full':
          this.btns = [
            {to: "/dashboard", title: "Обзор", icon: "mdi-monitor-dashboard"},
            {to: "/accounts", title: "Помещения", icon: "mdi-account-multiple"},
            {to: "/calculations", title: "Начисления", icon: "mdi-calculator-variant"},
            {to: "/reg-program", title: "Ремонт", icon: "mdi-office-building"},
          ]
        break
        case 'short':
          this.btns = [
            {to: "/dashboard-simple", title: "Обзор", icon: "mdi-monitor-dashboard"},
            {to: "/calculations-simple", title: "Начисления", icon: "mdi-calculator-variant"},
            {to: "/reg-program-simple", title: "Ремонт", icon: "mdi-office-building"},
          ]
        break
//        default: this.logout()
      }
    },
    wsCreateConnection() {
      if ((this.ws == null) && (this.$store.state.loggedIn)) {
        let wsURL = this.$store.getters.conf.wsURL
        if (wsURL != '') {
          var vm = this
          this.ws = new WebSocket(wsURL)
          this.ws.onmessage = function (event) {
            if (event.data == 'login') {
              vm.ws.send('login|' + vm.$store.state.login)
            } else {
              if (event.data != 'OK') {
                let arr = event.data.split('^')
                if (arr[0] == 'event') {
                  vm.$store.commit('receiveEvent', {evTime: arr[1], evOwner: arr[2], evDir: arr[3]})
                } else {
                  vm.$store.commit('receiveMessage', event.data)
                }
              }
            }
          }
        }
      }
    },
    logout() {
      this.$store.commit('loggedOut')
      this.$router.push({name: 'Login'})
    },
    setViewHeight() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
  },
  computed: {
    appTitleText() {
      return this.$store.state.title != "" ? this.$store.state.title : "ЛК - Фонд Капремонта"
    },
    customerTitle() {
      return this.$store.state.customer.hasOwnProperty("LastName") ? this.$store.state.customer.LastName + " " + this.$store.state.customer.FirstName + " " + this.$store.state.customer.MiddleName : ""
    },
  },
}
</script>


<style scoped>

>>> .v-list-item__content  {
  justify-content: center;
}

>>> .v-list-item__content .v-image {
  max-width: 50%;
  height: auto;
  margin: 20px 0 0 0;
}

/**
  Overwrite vuetify's default v-application--wrap min-height: 100vh;
  Due to the many problems with vh on mobile devices.
 */
.gp-full-height ::v-deep .v-application--wrap {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}


</style>
