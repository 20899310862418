import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/Dashboard.vue')
  },
  {
    path: '/accounts',
    name: 'Accounts',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/Accounts.vue')
  },
  {
    path: '/calculations',
    name: 'Calculations',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/Calculation.vue')
  },
  {
    path: '/payment',
    name: 'Payment',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/Payment.vue')
  },
  {
    path: '/reg-program',
    name: 'RegProgram',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/RegProgram.vue')
  },
 {
    path: '/messaging',
    name: 'Messaging',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/MessagingSimple.vue')
  },
//  {
//    path: '/inspection',
//    name: 'Inspection',
//    meta: { layout: 'Main', auth: true },
//    component: () => import('../components/Inspection.vue')
//  },
//  {
//    path: '/messaging',
//    name: 'Messaging',
//    meta: { layout: 'Main', auth: true },
//    component: () => import('../components/Messaging.vue')
//  },
//  {
//    path: '/messaging/new',
//    name: 'MessagingNew',
//    meta: { layout: 'Main', auth: true },
//    component: () => import('../components/MessagingNew.vue')
//  },
  {
    path: '/my-data',
    name: 'MyData',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/MyData.vue')
  },
//  {
//    path: '/components',
//    name: 'Components',
//    meta: { layout: 'Main', auth: true },
//    component: () => import('../components/Components.vue')
//  },
  {
    path: '/login',
    name: 'Login',
    meta: { layout: 'Login', auth: false },
    component: () => import('../components/AuthMobileForm.vue')
  },
  {
    path: '/login-registration',
    name: 'LoginRegistration',
    meta: {layout: 'Login', auth: false},
    component: () => import('../components/LoginRegistrationForm.vue')
  },
  {
    path: '/login-simple',
    name: 'LoginSimple',
    meta: { layout: 'Login', auth: false },
    component: () => import('../components/AuthSimpleForm.vue')
  },
  {
    path: '/dashboard-simple',
    name: 'DashboardSimple',
    meta: {layout: 'Main', auth: true},
    component: () => import('../components/DashboardSimple.vue')
  },
  {
    path: '/calculations-simple',
    name: 'CalculationsSimple',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/CalculationSimple.vue')
  },
  {
    path: '/reg-program-simple',
    name: 'RegProgramSimple',
    meta: { layout: 'Main', auth: true },
    component: () => import('../components/RegProgramSimple.vue')
  },

  {
    path: '*',
    redirect: '/login',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
//  console.log(store)
  if (to.matched.some(record => record.meta.auth)) {
    if (!store.state.loggedIn) {
//      console.log(localStorage)
      if ((localStorage.getItem('kaprem_login') !== null)&&
          (localStorage.getItem('kaprem_token') !== null)&&
          (localStorage.getItem('kaprem_doctkn') !== null)&&
          (localStorage.getItem('kaprem_account') !== null)&&
          (localStorage.getItem('kaprem_room') !== null)&&
          (localStorage.getItem('kaprem_mode') !== null)) {
        store.commit('loggedIn',
          { login: localStorage.kaprem_login,
            token: localStorage.kaprem_token,
            doctkn: localStorage.kaprem_doctkn,
            account: localStorage.kaprem_account,
            room: localStorage.kaprem_room,
            mode: localStorage.kaprem_mode,
          }
        )
        if (localStorage.getItem('kaprem_sel_account') !== null) store.commit('setSelAccount', localStorage.kaprem_sel_account)
        if (localStorage.getItem('kaprem_sel_period') !== null) store.commit('setSelPeriod', localStorage.kaprem_sel_period)
      }
    }
    if (store.state.loggedIn) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router
