import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/lib/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#003592',
        secondary: '#b8c7e1',
        accent: '#00cbe2',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#E5E5E5',
      },
      dark: {
        primary: '#b8c7e1',
        secondary: '#9f9f9f',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#003592',
      },
    },
    dark: false,
  },
    lang: {
      locales: { ru },
      current: 'ru',
    },
});
