import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router'

Vue.use(Vuex, axios)

export default new Vuex.Store({
  state: {
    title: "",
    loggedIn: false,
    mode: '',
    conf_dev: {
      apiURL: 'http://127.0.0.1:8006/api',
      docURL: 'http://127.0.0.1:8006/docs',
      payForm: 'http://127.0.0.1:8006/pay/form',
      wsURL: '',
    },
    conf_dev_ws: {
      apiURL: 'http://127.0.0.1:8006/api',
      docURL: 'http://127.0.0.1:8006/docs',
      payForm: 'http://127.0.0.1:8006/pay/form',
      wsURL: 'ws://127.0.0.1:8000/ws',
    },
    conf_prod: {
      apiURL: 'https://lk.kapremont19.ru/api',
      docURL: 'https://lk.kapremont19.ru/docs',
      payForm: 'https://lk.kapremont19.ru/pay/form',
      wsURL: '',
    },
    conf_prod_ws: {
      apiURL: 'https://lk.kapremont19.ru/api',
      docURL: 'https://lk.kapremont19.ru/docs',
      payForm: 'https://lk.kapremont19.ru/pay/form',
      wsURL: 'wss://lk.kapremont19.ru/ws',
    },
    period: '',
    periodLast: '',
    year: 0,
    login: '',
    account: '',
    room: '',
    token: '',
    doctkn: '',
    localFlagsDefaults: {
      helpDashboard: true,
      helpAccounts: true,
      helpCalculations: true,
      helpPayment: true,
      helpRegProgram: true,
      helpInspection: true,
      helpMessaging: true,
      helpData: true,
    },
    localFlags: {
      helpDashboard: true,
      helpAccounts: true,
      helpCalculations: true,
      helpPayment: true,
      helpRegProgram: true,
      helpInspection: true,
      helpMessaging: true,
      helpData: true,
    },
    localFlagsNames: {
      helpDashboard: 'Подсказка в обзоре',
      helpAccounts: 'Подсказка в списке лицевых счетов',
      helpCalculations: 'Подсказка в начислениях',
      helpPayment: 'Подсказка в платежах',
      helpRegProgram: 'Подсказка в региональной программе',
      helpInspection: 'Подсказка в народном контроле',
      helpMessaging: 'Подсказка в обращених',
      helpData: 'Подсказка в редактировании регистрационных данных',
    },
    acl: {},
    msgs: [],
    events: [],
    loadingData: true,
    sortedAccounts: [],
    selAccount: '',
    selPeriod: '',
    // global customer storage begin
    dashboard: {},
    customer: {},
    accounts: {},
    houses: {},
    municipalitys: {},
    actualDate: '',
    authDate: '',
    //profile: '',
    // global customer storage end
  },
  mutations: {
    setSelAccount(state, account) {
      state.selAccount = account
      localStorage.kaprem_sel_account = account
    },
    setSelPeriod(state, period) {
      state.selPeriod = period
      localStorage.kaprem_sel_period = period
    },
    setCurrentPeriod(state, period) {
      state.period = period
      state.year = parseInt(period.slice(-4), 10)
      state.actualDate = '01.' + period.slice(0, 2) + '.' + period.slice(-4)
      state.periodLast =  period.slice(-4) + '-' + period.slice(0, 2)
    },
    setTitle(state, title) {
      state.title = title
    },
    setAuthDate(state, val) {
      state.authDate = val
    },
    beforeLoggedIn(state, prm) {
      state.login = prm.login
      state.account = prm.account
      state.mode = prm.mode
    },
    loggedIn(state, prm) {
      state.loggedIn = true
      state.login = prm.login
      localStorage.kaprem_login = prm.login
      state.account = prm.account
      localStorage.kaprem_account = prm.account
      state.room = prm.room
      localStorage.kaprem_room = prm.room
      state.mode = prm.mode
      localStorage.kaprem_mode = prm.mode
      state.token = prm.token
      localStorage.kaprem_token = prm.token
      state.doctkn = prm.doctkn
      localStorage.kaprem_doctkn = prm.doctkn
      if (localStorage.getItem('kaprem_localFlags') === null) {
        state.localFlags = Object.assign({}, state.localFlagsDefaults)
        localStorage.kaprem_localFlags = JSON.stringify(state.localFlags)
      } else {
        state.localFlags = Object.assign({}, JSON.parse(localStorage.kaprem_localFlags))
      }
      //console.log(window.location.href)
    },
    changeLocalFlag(state, flag) {
      state.localFlags[flag] = ! state.localFlags[flag]
      if (state.mode != 'short') localStorage.kaprem_localFlags = JSON.stringify(state.localFlags)
    },
    resetLocalFlags(state) {
      state.localFlags = Object.assign({}, state.localFlagsDefaults)
      localStorage.kaprem_localFlags = JSON.stringify(state.localFlags)
    },
    loggedOut(state) {
      state.loggedIn = false
      localStorage.removeItem('kaprem_login')
      localStorage.removeItem('kaprem_account')
      localStorage.removeItem('kaprem_room')
      localStorage.removeItem('kaprem_token')
      localStorage.removeItem('kaprem_doctkn')
      localStorage.removeItem('kaprem_mode')
      state.login = ''
      state.account = ''
      state.room = ''
      state.token = ''
      state.doctkn = ''
      state.mode = ''
    },
    wsURLStorage(state) {
      if (state.wsURL == '') {
        state.wsURL = localStorage.getItem('kaprem_wsurl') ?? ''
      }
    },
    receiveMessage(state, msg) {
      if (state.msgs.length > 9) state.msgs.shift()
      state.msgs.push(msg)
    },
    receiveEvent(state, event) {
      if (state.events.length > 9) state.events.shift()
      state.events.push(event)
    },
    setDashboard(state, obj) {
      state.dashboard = Object.assign({}, obj)
    },
    setCustomer(state, obj) {
      state.customer = Object.assign({}, obj)
    },
    setCustomerAccount(state, obj) {
      Vue.set(state.customer.Accounts, obj.Account, obj)
    },
    delCustomerAccount(state, obj) {
      Vue.delete(state.customer.Accounts, obj.Account)
    },
    setAccount(state, obj) {
      Vue.set(state.accounts, obj.Account, obj)
    },
    setHouse(state, obj) {
      Vue.set(state.houses, obj.HouseCode, obj)
    },
    setMunicipality(state, obj) {
      Vue.set(state.municipalitys, obj.MunicipalityCode, obj)
    },
  },
  getters: {
    conf(state) {
      if (process.env.NODE_ENV === 'production') {
        return state.conf_prod
      } else {
        return state.conf_dev
      }
    }
  },
  actions: {
    loadDashboard(st) {
      // axios load dashboard
      //console.log('axios load dashboard')
      st.state.loadingData = true
      let jsonStr = JSON.stringify( { login: st.state.login, token: st.state.token } )
      axios.post(st.getters.conf.apiURL + '/app/customer/full', jsonStr ).then((response) => {
        if (response.data.code == 200) {
          st.commit('setCurrentPeriod', response.data.period)
          let year_current = parseInt(response.data.period.slice(-4), 10)
          st.commit('setAuthDate', response.data.auth)
          st.commit('setDashboard', response.data.dash)
          st.commit('setCustomer', response.data.customer)
          let accountPushed = false
          if (response.data.accounts !== null) {
            for (let account of response.data.accounts) {
              st.commit('setAccount', account)
              if (st.state.sortedAccounts.indexOf(account.Account) < 0) {
                st.state.sortedAccounts.push(account.Account)
                accountPushed = true
              }
            }
            if (accountPushed) {
              st.state.sortedAccounts.sort()
            }
          }
          if (response.data.houses !== null) {
            for (let house of response.data.houses) {
              for (let idx in house.OverhaulData) {
                let year_start = parseInt(house.OverhaulData[idx].PeriodStart.slice(-4), 10)
                let year_stop = parseInt(house.OverhaulData[idx].PeriodStop.slice(-4), 10)
/*
                if (house.OverhaulData[idx].ActualYear == '') {
                  if ((year_stop >= year_current) && (year_current >= year_start)) {
                    house.OverhaulData[idx].ActualYear = year_current
                  } else {
                    if (year_current < year_start) {
                      house.OverhaulData[idx].ActualYear = year_start
                    } else {
                      house.OverhaulData[idx].ActualYear = year_stop
                    }
                  }
                }
*/
                switch (house.OverhaulData[idx].Status) {
                  case 'NotStarted':
                    house.OverhaulData[idx].Icon = 'mdi-hammer-wrench'
                    house.OverhaulData[idx].Color = 'var(--color-brand-gray)'
                    house.OverhaulData[idx].StatusText = 'Работы не начаты'
                  break;
                  case 'InProgress':
                    house.OverhaulData[idx].Icon = 'mdi-hammer-wrench'
                    house.OverhaulData[idx].Color = 'var(--color-brand-cyan)'
                    house.OverhaulData[idx].StatusText = 'Работы выполняются'
                  break;
                  case 'Completed':
                    house.OverhaulData[idx].Icon = 'mdi-hammer-wrench'
                    house.OverhaulData[idx].Color = 'var(--color-brand-blue)'
                    house.OverhaulData[idx].StatusText = 'Работы завершены'
                  break;
                  default:
                    house.OverhaulData[idx].Icon = 'mdi-hammer-wrench'
                    house.OverhaulData[idx].Color = 'var(--color-brand-gray)'
                    house.OverhaulData[idx].StatusText = 'Работы не выполнялись'
                }
              }
              st.commit('setHouse', house)
            }
          }
          if (response.data.municipalitys !== null) {
            for (let municipality of response.data.municipalitys) {
              st.commit('setMunicipality', municipality)
            }
          }
        } else {
          router.push({ name: 'Login'}).catch(() => {})
        }
      }).catch((error) => {
        console.log(error)
        router.push({ name: 'Login'}).catch(() => {})
      }).finally(() => (st.state.loadingData = false))
    },
  },
  modules: {
  }
})
