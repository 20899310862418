<template>
  <v-app id="lk-kapremont19">
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    props: {
      source: String,
    },
  }
</script>

<style scoped>
.theme--light.v-application {
  background-color: var(--color-brand-gray-light);
}
</style>
